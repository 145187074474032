<template>
    <div class="medium-shelves">
        <store-product 
            :data_products="data_products">
        </store-product>
        <!-- Footer -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Estantería Mediana',
		meta: [
			// Ummami Express
			{ name: 'description', content:'fabricantes de estantería metálica para sistemas de almacenamiento en la ciudad de bucaramanga, barranquilla, valledupar, buenaventura, cucuta' },
			{ name: 'keywords', content:'estantería mediana, estantería metálica mediana, rack de almacenamiento, carga selectiva, rack selectivo, montacargas, estanterías para palletsestanteria metalica, estanterias metalicas, estanteria metalica bucaramanga, estanteria metalica barranquilla, estanteria pesada, estanteria liviana, fabrica de estanterias metalicas, racks de almacenamiento, estanterias metalicas en bucaramanga, estanterias metalicas en barranquilla' },
			{ name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Estantería Mediana'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'fabricantes de estantería metálica para sistemas de almacenamiento en la ciudad de bucaramanga, barranquilla, valledupar, buenaventura, cucuta'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Estantería Mediana'},
			{name: 'twitter:description', content: 'fabricantes de estantería metálica para sistemas de almacenamiento en la ciudad de bucaramanga, barranquilla, valledupar, buenaventura, cucuta' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Estantería Mediana'},
			{itemprop: 'description', content: 'fabricantes de estantería metálica para sistemas de almacenamiento en la ciudad de bucaramanga, barranquilla, valledupar, buenaventura, cucuta' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    metaInfo() {
        return {
            title: this.page_title,
        }
    },
    data() {
        return {
            page_title: 'Estantería Mediana',
            data_products: {
                title_page: 'Estantería mediana',
                types: [
                    // { text: 'Todos', value: 'todos' },
                    { text: 'Trial 200Kg/Nivel', value: 'estanteria-trial' },
                    { text: 'Mini Rack 50 300-500 Kg/Nivel', value: 'estanteria-minirack' }
                ],
                products: [
                    {
                        name: 'Trial sin tornillos 200 kg/nivel',
                        description: `
                            <p>
                                Diseñada de ángulos sin
                                tornillos, para negocios, archivos u hogar. Está compuesta por ángulos y
                                travesaños en láminas de acero de 1.9 mm de espesor (Cal 14).
                                Entrepaños de MDF con malla electrosoldada de alta calidad.
                            </p> 
                            <p>CARACTERÍSTICAS</p>
                            <ul class="pl-3">
                                <li class="mb-2">Muy fácil de ensamble.</li>
                                <li class="mb-2">Acabados de pintura electrostática.</li>
                                <li class="mb-2">Sin herramientas para instalar.</li>
                                <li class="mb-2">Durable.</li>
                            </ul> 
                        `,
                        url_image: 'estanterias-metalicas/mediana/productos/estanteria-trial/trial_1.jpg',
                        slides: [
                            'estanterias-metalicas/mediana/productos/estanteria-trial/trial_1.jpg',
                            'estanterias-metalicas/mediana/productos/estanteria-trial/trial_3.png',
                            'estanterias-metalicas/mediana/productos/estanteria-trial/trial_2.jpg'
                        ],
                        price: '',
                        tags: ['estanteria-trial']
                    },
                    {
                        name: 'Mini Rack 50 Desde 300-500 Kg/Nivel',
                        description: `
                            <p>
                                Especialmente diseñado para productos de pesos desde (350 hasta 500
                                kg/nivel). Se pueden manipular de forma manual y permite un control
                                riguroso de inventarios y alto flujo de stocks.
                                Su sistema de armado es fácil y rápido y no necesita tornillos. Además,
                                permite complementarse con diferentes accesorios de acuerdo a las
                                necesidades de cada cliente.
                            </p> 
                            <p>CARACTERÍSTICAS</p>
                            <ul class="pl-3">
                                <li class="mb-2">Permite modificaciones constructivas.</li>
                                <li class="mb-2">Permite estanterías para picking con pasillos elevados.</li>
                                <li class="mb-2">Permite adicionar dispositivos para colgar prendas.</li>
                                <li class="mb-2">Permite incrustar muebles o gaveteros y divisiones móviles.</li>
                            </ul> 
                            <p>APLICACIONES</p>
                            <ul class="pl-3">
                                <li class="mb-2">Archivo inactivo.</li>
                                <li class="mb-2">Almacenamiento de llantas.</li>
                                <li class="mb-2">Droguerías.</li>
                                <li class="mb-2">Confecciones.</li>
                                <li class="mb-2">Ferreterías.</li>
                                <li class="mb-2">Cuartos fríos.</li>
                                <li class="mb-2">Talleres.</li>
                                <li class="mb-2">Pinturas.</li>
                                <li class="mb-2">Repuestos automotores.</li>
                            </ul> 
                        `,
                        url_image: 'estanterias-metalicas/mediana/productos/mini-rack-50/mini-rack-1.jpg',
                        slides: [
                            'estanterias-metalicas/mediana/productos/mini-rack-50/mini-rack-1.jpg',
                            'estanterias-metalicas/mediana/productos/mini-rack-50/mini-rack-2.jpg',
                            'estanterias-metalicas/mediana/productos/mini-rack-50/mini-rack-3.jpg',
                            'estanterias-metalicas/mediana/productos/mini-rack-50/mini-rack-4.jpg'
                        ],
                        price: '',
                        tags: ['estanteria-minirack']
                    }
                ]
            }
        }
    },
   computed: {
        url_tag() {
            return this.$route.params.tag;
        }
    },
    watch: {
        url_tag() {
            this.page_title = this.url_tag.replaceAll('-', ' ').capitalize();
        }
    }
}
</script>